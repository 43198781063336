import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-preset-form',
  templateUrl: './preset-form.component.html',
  styleUrls: ['./preset-form.component.scss']
})
export class PresetFormComponent implements OnInit {

  constructor(private router:Router, private route: ActivatedRoute, private dataService: DataService, private titleService: Title) { }

  loading:any = false;
  token:any = "";
  data:any = {}
  selectedForm:any = {patient:{}};
  submitting:any = false;

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    //this.login();
    this.loadForm();

    this.getCookie();
  }

  loadForm(){
    this.loading = true;
    this.dataService.getPresetForm(this.token).subscribe( (data: any )=> {
      this.loading = false;
      this.selectedForm = data;
      
      // Set the <title> attribute of the page
      if (this.selectedForm && this.selectedForm.form_title) {
        this.titleService.setTitle(this.selectedForm.form_title);
      }

    } );
  }

  selectOption(question, option) {
  	
	  if (this.selectedForm.data[question].options[option].state == 1) {
		  this.selectedForm.data[question].options[option].state = 0;
  	} else {
  		//var context = this;
      
      if (this.selectedForm.data[question].is_multiselect == 0) {
        this.selectedForm.data[question].options.forEach(function(i, k) {
          i.state = 0;
        })
      }

  		this.selectedForm.data[question].options[option].state = 1;
  	}

    let data = {
      token:this.token,
      patient:this.selectedForm.patient.id,
      form_id:this.selectedForm.form_id,
      submission_id: this.selectedForm.submission_id,
      //@ts-ignore
      question:this.selectedForm.data[question].id,
      //@ts-ignore
      option: this.selectedForm.data[question].options[option].option_id,
      state:this.selectedForm.data[question].options[option].state
      
    };

    
    this.dataService.postRadioResponse(data).subscribe( (res:any) => {
      //
    }); 

  	
  }



  markSurvey(question, option) {
    
    let context = this;
    //@ts-ignore
    this.selectedForm.survey_questions[question].responses.forEach(function(item, index){
      //@ts-ignore
      context.selectedForm.survey_questions[question].responses[index].value = 0;
    });  
    
    //@ts-ignore
    this.selectedForm.survey_questions[question].responses[option].value = 1;

    let data = {
      token:this.token,
      patient:this.selectedForm.patient.id,
      form_id:this.selectedForm.form_id,
      submission_id: this.selectedForm.submission_id,
      //@ts-ignore
      question:this.selectedForm.survey_questions[question].id,
      //@ts-ignore
      option: this.selectedForm.survey_questions[question].responses[option].id
      
    };

    

    
    this.dataService.postSurveyResponse(data).subscribe( (res:any) => {
      
      //@ts-ignore
      this.submission_id = res.submission_id;
      
    }); 

    //console.log('question', this.selectedForm.survey_questions[question].id);
  }

  submitToClinic(){
    this.submitting = true;
    let data = {
      token:this.token
    }

    this.dataService.postSubmitToClinic(data).subscribe( (res:any) => {
        this.submitting = false;
        alert(res.msg);

    }); 
  }
  
  getCookie() {
    /*const name = "invoiceToken";
    const cookie = document.cookie.split('; ').find(row => row.startsWith(name + '='));
    const cookieVal =  cookie ? cookie.split('=')[1] : null;
    console.log("cookie", cookieVal); */
    
  }

}
