<div class="container" *ngIf="loading">
  <div class="row mt-4">
      <div class="col-12 text-center">
          <p>
              Loading the form. Please wait.
          </p>
      </div>
  </div>
</div>
<div class="container" *ngIf="!selectedForm.status">
    <div class="row mt-4">
        <div class="col-12 text-center">
            <p>
                {{selectedForm.msg}}
            </p>
        </div>
    </div>
</div>

<div class="container" *ngIf="selectedForm.status == true">
    <div class="row form-header">
      <div class="col-12">
        <h4 class="mt-4 text-center">{{selectedForm.form_title}}</h4>
      </div>
    </div>

    <div class="patient-header d-block d-md-none">
        <div class="row ">
            <div class="col-2">
                <img class="img-fluid mt-2" src="assets/img/user-placeholder.png" alt="">
            </div>
    
            <div class="col-6">
                <small>Submission for</small>
                <p class="mb-0">
                    {{selectedForm.patient.name}}
                </p>
            </div>
        </div>
    </div>

    <div class="patient-header d-md-block d-none more-margin">
        <div class="row ">
            <div class="col-1">
                <img class="img-fluid mt-2"  src="assets/img/user-placeholder.png" alt="">
            </div>
    
            <div class="col-6 pt-4">
                <small>Submission for</small>
                <p class="mb-0">
                    {{selectedForm.patient.name}}
                </p>
            </div>
        </div>
    </div>

    

    

    <div class="row">
        <div class="col-12">

            
            <div class="question" *ngFor="let question of selectedForm.data; let i = index">
                <hr *ngIf="id == null"/>
                <p class="text-right" *ngIf="id == '0'"> 
    
                  <img src="assets/img/close.png" style="width:15px; cursor: pointer;" (click)="deleteQuestion(question.id)" > 
                </p>
                
                
                <p *ngIf="question.is_heading == 1" class="question-text title-question"> 
                  {{question.question}}
                </p>
                <p *ngIf="question.is_heading == 0" class="question-text"> 
                  {{question.question}}
                </p>
                <p *ngIf="question.description != NULL" class="question-description">
                  {{question.description}}
                </p>
                
    
               <!--<p *ngIf="question.is_subtitle == 1" class="question-text subtitle-question"> 
                  {{question.question}}
                </p>
    
                <p *ngIf="question.is_heading == 0 && question.is_subtitle == 0" class="question-text"> 
                  {{question.question}}
                </p>-->
                
    
                <div class="options">
                  <ng-container *ngFor="let o of question.options; let j = index">
    
                    <ng-container *ngIf="o.type == 1">
                      <div class="option" [ngClass]="(o.state == 1) ? 'selected' : '' "  (click)="selectOption(i,j)" >
                        <span>{{o.text}}</span>
                        <img *ngIf="o.state == 1" src="assets/img/radio-active.svg">
                        <img *ngIf="o.state == 0" src="assets/img/radio-inactive.svg">
    
                      </div>
                    </ng-container>
                    <ng-container *ngIf="o.type==2">
                      <div class="option-input">
                        <label *ngIf="o.text.length > 0" class="field-caption"> {{o.text}} </label>
                        <input class="large-text-input" type="text" name="" (input)="updateResponse(i,j, $event.target.value)" [value]="o.response">
                      </div>
                    </ng-container>
                    <ng-container *ngIf="o.type==3">
                      <div class="option-input">
                        <label *ngIf="o.text.length > 0" class="field-caption"> {{o.text}} </label>
                        <input class="small-text-input" type="text" name="" (input)="updateResponse(i,j, $event.target.value)" [value]="o.response">
                      </div>
                    </ng-container>
                    <ng-container *ngIf="o.type == 4">
                      <div class="option" [ngClass]="(o.state == 1) ? 'selected' : '' "  (click)="selectMultiOption(i,j)" >
                        <span>{{o.text}}</span>
                        <img *ngIf="o.state == 1" src="assets/img/radio-active.svg">
                        <img *ngIf="o.state == 0" src="assets/img/radio-inactive.svg">
    
                      </div>
                    </ng-container>
                    <ng-container *ngIf="o.type==6">
                      <div class="option-input" style="font-size: 12px; width: 100%;">
                        <textarea [placeholder]="o.text" (input)="updateResponse(i,j, $event.target.value)">{{o.response}}</textarea>
                      </div>
                    </ng-container>
                    
                    <ng-container *ngIf="o.type == 7">
                                                
                      <ng-container *ngIf="o.response == null || o.response == '' ">
                        <input class="floating-input" type="file" placeholder="Upload file" (change)="uploadFile(i,j,$event)" >
                      </ng-container>
                      <small *ngIf="fileUploadFlag == 1" class="uf-file-notif" >Uploading file...</small>
                      <ng-container *ngIf="o.response !== null && o.response !== '' ">
                        <a href="javascript:void(0);" class="btn btn-primary btn-sm" (click)="openFile(o.response)" > View file </a>
                        &nbsp; &nbsp;
                        <a href="javascript:void(0);" class="btn btn-danger btn-sm" (click)="deleteFile(i,j)">Delete File</a>
  
                        <br/>
                        <small *ngIf="fileOpenFlag == 1" class="uf-file-notif" >Opening file...</small>
                        <small *ngIf="fileDeleteFlag == 1" class="uf-file-notif" >Deleting file...</small>
                        
  
                      </ng-container>
  
                    </ng-container>
  
    
                    <ng-container *ngIf="o.type==9">
                      <div class="option-input" [attr.data-question]="i" [attr.data-option]="j" style="font-size: 12px; width: 100%;">
                        <textarea class="rich-editor" [placeholder]="o.text">{{o.response}}</textarea>
                      </div>
                    </ng-container>
    
                    <ng-container *ngIf="o.type==10">
                      <p style="display: block;"><br/></p>
                      
                    </ng-container>
    
                    <!--<div style="flex-basis: 100%" *ngIf="j%3==0 && j != 0"></div> -->
    
                  
                  </ng-container>
    
                  <ng-container *ngIf="question.id == 163">
                    
                    <table class="table table-bordered">
                      <thead>
                        <th>Reflexes/Reaction</th>
                        <th>First Examination Age</th>
                        <th>Second Examination Age</th>
                        <th>Third Examination Age</th>
                        <th>Fourth Examination Age</th>
  
                      </thead>
  
                      <tbody>
                        <tr *ngFor="let row of devAssessment">
                          <td> {{row.question}} </td>
                          <td>
                            <select class="form-control" (change)="setDaResponse(row.question_id, 0, $event.target.value)">
                              <option value="0"></option>
                              <option value="1" [selected]="row.response[0] == 1">Absent</option>
                              <option value="2" [selected]="row.response[0] == 2">Present</option>
                              <option value="3" [selected]="row.response[0] == 3">Exaggerated</option>
                              <option value="4" [selected]="row.response[0] == 4">Clonus</option>
                            </select>
                          </td>
                          <td>
                            <select class="form-control" (change)="setDaResponse(row.question_id, 1, $event.target.value)">
                              <option value="0"></option>
                              <option value="1" [selected]="row.response[1] == 1">Absent</option>
                              <option value="2" [selected]="row.response[1] == 2">Present</option>
                              <option value="3" [selected]="row.response[1] == 3">Exaggerated</option>
                              <option value="4" [selected]="row.response[1] == 4">Clonus</option>
                            </select>
                          </td>
                          <td>
                            <select class="form-control" (change)="setDaResponse(row.question_id, 2, $event.target.value)">
                              <option value="0"></option>
                              <option value="1" [selected]="row.response[2] == 1">Absent</option>
                              <option value="2" [selected]="row.response[2] == 2">Present</option>
                              <option value="3" [selected]="row.response[2] == 3">Exaggerated</option>
                              <option value="4" [selected]="row.response[2] == 4">Clonus</option>
                            </select>
                          </td>
                          <td>
                            <select class="form-control" (change)="setDaResponse(row.question_id, 3, $event.target.value)">
                              <option value="0"></option>
                              <option value="1" [selected]="row.response[3] == 1">Absent</option>
                              <option value="2" [selected]="row.response[3] == 2">Present</option>
                              <option value="3" [selected]="row.response[3] == 3">Exaggerated</option>
                              <option value="4" [selected]="row.response[3] == 4">Clonus</option>
                            </select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
    
                  </ng-container>
                  
                  
                </div>
  
                <ng-container *ngIf="question.survey_id != 0 &&  surveys[question.survey_id] !== 'undefined' ">
                  
                  <!-- survey-->
                  <div class="row form-wrap" >
                    <div class="col-12">
                      
              
                      <div class="row option-row" style="margin-bottom: 20px;">
                        <div class="col-6">
                          
                        </div>
              
                        <div class="col-6">
                          <div class="row">
                            
                            <ng-container *ngFor="let option of surveys[question.survey_id].survey_options">
                              <div [ngClass]="(surveys[question.survey_id].survey_options.length) > 6 ? 'col-1' : 'col-2' ">
                                <small>{{option.option}}</small>  
                              </div>
                            </ng-container>
                            
                          </div>
                          
                        </div>
                      </div>
                      
              
                      <div class="row" *ngFor="let category of surveys[question.survey_id].survey_questions; let cat = index">
                        <div class="col-12 cat-wrap">
                          <p class="cat-title">  {{category.title}} </p>
              
                          <div class="row survey-row" *ngFor="let sq of category.questions">
                            <div class="col-6">
                              <span>{{sq.question}}</span>
                            </div>
                            <div class="col-6">
              
                              <div class="row">
                                <ng-container *ngFor="let option of surveys[question.survey_id].survey_options">
                                  <div [ngClass]="(surveys[question.survey_id].survey_options.length) > 6 ? 'col-1' : 'col-2' ">
                                    <a href="javascript:void(0);" class="btn-survey" (click)="markSfOption(sq, option)">
                                      <img src="assets/img/tick-inactive.png" *ngIf="sq.response.value !== option.value" style="opacity: 0.4">
                                      <img src="assets/img/tick-active.png" *ngIf="sq.response.value == option.value">
                                      
                                    </a>
                                  </div>
                                </ng-container>
                              </div>
                            
                              
                            </div> <!-- /col-6-->
                          </div>
                            
              
                          
              
                        </div>
                      </div>
                      
                      
              
                      
                    </div>
                  </div>
                  <!-- /survey-->
  
                </ng-container>
    
                <ng-container *ngIf="question.table_id != 0 &&  tables[question.table_id] !== 'undefined' ">
  
                  <table class="table table-bordered">
                    <thead>
                      <th> {{tables[question.table_id].column_caption}} </th>
                      <th *ngFor="let option of tables[question.table_id].options">{{option.option}}</th>
                    </thead>
  
                    <tbody>
                      <tr *ngFor="let q of tables[question.table_id].questions">
                        <td> <span [ngClass]="(q.is_caption == 1) ? 'tr-caption' : ''"> {{q.question}} </span> </td>
                        <td *ngFor="let option of tables[question.table_id].options"> 
                          <input *ngIf="option.type == 1" type="text" class="form-control" value="{{q.response.value[option.id]}}" (input)="updateTableResponse(q, option, $event.target.value)" /> 
                          <textarea *ngIf="option.type == 2" class="form-control" (input)="updateTableResponse(q, option, $event.target.value)">{{q.response.value[option.id]}}</textarea>
                        </td>
                      </tr>
                    </tbody>
  
                  </table>
  
                </ng-container>
    
              </div>

              <!-- suvey goes here -->
              <div *ngIf="selectedForm.is_survey == 1">
                <h5>Legends</h5>
    
                <p *ngFor="let option of selectedForm.survey_options">
                   <strong>{{option.value}}</strong>:  {{option.option}}		 
                </p>
    
                
                <hr/>
    
                <ng-container *ngFor="let question of selectedForm.survey_questions; let q = index">
                  <div class="row survey-row">
                    <div class="col-6">
                      <span *ngIf="question.is_title == 0">{{question.question}}</span>
                      <span *ngIf="question.is_title == 1"> <strong> {{question.question}} </strong></span>
                    </div>
      
                    <div class="col-6">
                      <div class="row" *ngIf="question.is_title == 0">
                        <ng-container *ngFor="let option of selectedForm.survey_options; let i = index">
                          <div [ngClass]="(selectedForm.survey_options.length) > 6 ? 'col-1' : 'col-2' ">
                            <a href="javascript:void(0);" class="btn-survey" (click)="markSurvey(q, i)">
                              <!--<img src="assets/img/tick-inactive.png" class="d-none d-md-block" *ngIf="question.responses[i].value == 0" style="opacity: 0.4">
                              <img src="assets/img/tick-active.png" class="d-none d-md-block" *ngIf="question.responses[i].value == 1">-->
  
                              <!-- Desk version with numbers -->
                              <span
                                  class="d-none d-md-block survey-m-opt desktop" 
                                  [ngClass]="{'selected': question.responses[i].value == 1}"
                              >
                                  {{ i }}
                              </span>
  
                              <!-- Mobile version with numbers -->
                              <span
                                  class="d-block d-md-none survey-m-opt" 
                                  [ngClass]="{'selected': question.responses[i].value == 1}"
                              >
                                  {{ i }}
                              </span>
  
                            </a>
                          </div>
                        </ng-container>
      
                      </div>
                      
                    </div>
                  </div>

                  <hr>
                </ng-container>

                
                

    
              </div>

              <div class="row mb-4">
                <div class="col-12 mb-4">
                  <button class="btn btn-success" (click)="submitToClinic()" *ngIf="!submitting">
                    Submit form
                  </button>

                  <button class="btn btn-secondary" (click)="submitting()" *ngIf="submitting">
                    Submitting
                  </button>

                </div>
              </div>
  
    
              
              <footer class="mb-4">
                <div class="row mb-4">
                  <div class="col-12 text-center mb-4">
                    <small>Powered by</small>
                    <p class="mt-2">
                      <a href="https://metanoa.ai" target="_blank">
                        <img class="d-none d-md-inline footer-logo" src="assets/img/logo-color.png" alt="">
                        <img class="d-inline d-md-none footer-logo-phone" src="assets/img/logo-color.png" alt="">
                      </a>
                    </p>

                  </div>
                </div>
              </footer>
            

        </div>
    </div>

  </div>