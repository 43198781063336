import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule }from'@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { PresetFormComponent } from './preset-form/preset-form.component';

const routes: Routes = [
  {
    path: '', component:AppComponent,
    children: [
      
    ]
  },
  {
    path: 'preset-form/:token', component:PresetFormComponent
  },
  
];

@NgModule({
  declarations: [
    AppComponent,
    PresetFormComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [RouterModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
