import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  //public REST_API_SERVER = "http://localhost/metanoa-api/frontend/web/?r="; //Dev
  public REST_API_SERVER = "https://webapi.metanoa.ai/?r="; //Prod

  constructor(private httpClient: HttpClient) { }

  /*public getPresetForm(token) {
    return this.httpClient.get(this.REST_API_SERVER + "shareable/fetch_preset_form&token="+token, {
      headers: { 'key': localStorage.getItem("key") }
    });
  } */

  public getPresetForm(token) {
    return this.httpClient.get(this.REST_API_SERVER + "shareable/fetch_preset_form&token="+token);
  }

  public postSurveyResponse(data){
    return this.httpClient.post(this.REST_API_SERVER + "shareable/save_survey_response", data);
  }

  public postRadioResponse(data){
    return this.httpClient.post(this.REST_API_SERVER + "shareable/save_radio_response", data);
  }

  public postSubmitToClinic(data){
    return this.httpClient.post(this.REST_API_SERVER + "shareable/submit_form_to_clinic", data);
  }
  
}
